import * as React from "react";
import { graphql, PageProps } from "gatsby";
import MainLayout from "../../layouts/main";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { Widget } from "@typeform/embed-react";
import { deIndexSeo } from "../../utils/deIndexSeo";

type PageData = {
  wpPage: {
    seo: Queries.WpPostTypeSEO;
    template: Queries.WpTypeFormPageTemplate;
  };
};

const TypeFormPage: React.FC<PageProps<PageData>> = ({ data: { wpPage } }) => {
  const {
    template: { typeform },
  } = wpPage;

  return (
    <MainLayout>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <Widget style={{ height: "90vh" }} id={typeform?.formId!} />
    </MainLayout>
  );
};

export default TypeFormPage;

export const query = graphql`
  query TypeFormPageTemplate($id: String) {
    wpPage(id: { eq: $id }) {
      id
      ...YoastSEOFragment
      template {
        ... on WpTypeFormPageTemplate {
          templateName
          typeform {
            formId
          }
        }
      }
    }
  }
`;
